<template>
  <div class="w-100 no-show-unit-ready" :class="{'ready': isReady}">
    <NewContractSection :visible="formSection == 1 && isReady" :contractObject="contractData" :jobDepartments="jobDepartments" :jobFunctions="jobFunctions" :companyTemplates="companyTemplates" 
                        :attachments="attachments" :companyAttachments="companyAttachments" :articles="articles" :infoTexts="infoTexts" ref="newcontract" />

    <EditNAWSection :visible="formSection == 2 && isReady" :contractObject="contractData" :nawObject="nawData" :nationalities="nationalities" :countries="countries" 
                    :documentTypes="documentTypes" ref="editnaw" />

    <UpdateContractSection :visible="formSection == 3 && isReady" :contractObject="contractData" :nawObject="nawData" :jobDepartments="jobDepartments" :jobFunctions="jobFunctions" 
                          :companyTemplates="companyTemplates" :attachments="attachments" :companyAttachments="companyAttachments" :articles="articles" ref="editcontract" />
  </div>

  <button type="button" class="d-none" id="sync-func-button" @click="syncJobFunctions"></button>

  <hr class="text-white">
</template>

<script>
  import UpdateContractSection from '@/components/employee/EmployeeEditContract.vue'
  import EditNAWSection from '@/components/employee/EmployeeNAW.vue'
  import NewContractSection from '@/components/employee/EmployeeNewContract.vue'
  import contractService from '@/services/ContractService'
  import countryService from '@/services/CountryService'
  import nationalityService from '@/services/NationalityService'
  import employeeService from '@/services/EmployeeService'
  import infoService from '@/services/InfoService'
  import templateService from '@/services/TemplateService'
  import documentTypeService from '@/services/DocumentTypeService'
  import companyService from '@/services/CompanyService'
  import IBAN from "iban";
  import moment from 'moment'
  import store from '@/store/user'
  import pell from 'pell'
  export default {
    name: 'Home',
    components: {
      NewContractSection,
      EditNAWSection,
      UpdateContractSection
    },
    data() {
      return{
        isEditing: false,
        isReady: false,
        formSection: 0,
        templateHeader: "",
        templateFooter: "",
        articles: [],
        countries: [],
        nationalities: [],
        templates: [],
        attachments: [],
        companyTemplates: [],
        companyAttachments: [],
        jobFunctions: [],
        jobDepartments: [],
        documentTypes: [],
        infoTexts: {},
        companyData: {},
        isEmployeeFillingInNAW: false,
        shouldGenerateContract: true,
        nawData: {
            "email": "",
            "first_name": "",
            "initials": "",
            "prefix": null,
            "last_name": "",
            "birth_place": "",
            "birth_date": "",
            "nationality": null,
            "gender": "",
            "description": null,
            "bsn_number": "",
            "iban": "",
            "phone_number": "",
            "identification_number": null,
            "identification_expiration_date": null,
            "identification_uploaded": null,
            "birth_country_id": null,
            "company_id": null,
            "identification_file_id": null,
            "identification_nmbrs_document_id": null,
            "employee_status_id": null,
            "address": {
                "postal_code": "",
                "house_number": "",
                "prefix": null,
                "street": "",
                "place": "",
                "latitude": null,
                "longitude": null,
                "country_id": null
            },
            "files": [null, null, null],
            "tax_compensation": 0,
            "tax_compensation_date": null,
            "tax_compensation_signature": null
        },
        oldContractData: {},
        contractData: {
          "employee": {
              "first_name": "",
              "prefix": null,
              "last_name": "",
              "email": "",
              "phone_number": ""
          },
          "job_function": {
              "name": "",
              "reference_number": "",
              "department": "",
              "start_date": "",
              "fixed_term": null,
              "fixed_term_expiration_date": null,
              "probation": false,
              "probation_expiration_date": "",
              "probation_expiration_month": 0,
              "fixed_term_expiration_month": 0,
              "employment_type": "",
              "skilled_worker": null,
              "timetable": "8,8,8,8,8,0,0,8,8,8,8,8,0,0",
              "hours": 40,
              "salary": "",
              "travel_compensation_type": "none",
              "tax_compensation": 0,
              "tax_compensation_days": null,
              "tax_compensation_distance": null,
              "additional_compensations": null,
              "travel_compensation": null,
              "travel_compensation_days": null,
              "travel_compensation_distance": null,
              "expenses_compensation": null,
              "phone_compensation": null,
              "direct_upload": null,
              "has_wage_tax_credit": 0,
              "total_work_days_at_home": null,
              "work_days_at_home_compensation": null,
              "debtor_function_id": null,
              "debtor_department_id": null
          },
          "generate_contract": null,
          "generate_date": null,
          "generate_place": null,
          "template_id": null,
          "document_view": null,
          "document": null,
          "signature": null,
          "sync": null,
          "company_id": null,
          "attachments": []
        },
        mail: { email_to: "", subject: "", email_body: "" },
        schedule: ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'],
        dict: {},
        pellEditor: null,
        editingProfession: false
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast'))
                  validityCheck = date > Date.now();
                else
                  validityCheck = date < Date.now();
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                case "bsn":
                  validityCheck = this.validateBSN(el);
                  shouldShowvalidity = false;
                  break;
                case "iban":
                  validityCheck = this.validateIBAN(el);
                  shouldShowvalidity = false;
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const steps = [0, this.$refs.newcontract.currentEmployeeStep, this.$refs.editnaw.currentNAWStep, this.$refs.editcontract.currentContractStep];
        const step = steps[this.formSection];
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        else if(this.$refs.editnaw.currentNAWStep == 2 && !this.nawData.files[0] && (!this.nawData.files || !this.nawData.files[0] ) && (!this.isEditing || this.nawData.new_identity_files)){
          alert(this.$t('errors.identity'));
          isValid = false;
        }
        else if(this.$refs.editnaw.currentNAWStep == 3 && this.contractData.job_function.tax_compensation && !this.nawData.files[2] ){
          alert(this.$t('errors.lhk'));
          isValid = false;
        }
        return isValid;
      },
      loadData(){
          countryService.index().then(response => {
              this.countries = response.data;
              this.countries.sort(function(x,y){ return x.name =='Nederland'  ? -1 : y.name =='Nederland' ? 1 : 0; });
          }).catch(this.toastError);
          nationalityService.index().then(response => {
              this.nationalities = response.data;
              this.nationalities.sort(function(x,y){ return x.name =='Nederlandse'  ? -1 : y.name =='Nederlandse' ? 1 : 0; });
          }).catch(this.toastError);
          infoService.index().then(response => {
              response.data.forEach(info => this.infoTexts[info.identifier] = info.description);
          }).catch(this.toastError);
          documentTypeService.index().then(response => this.documentTypes = response.data).catch(this.toastError);
      },
      getTemplates(){
          templateService.index().then(response => {
              this.attachments = response.data.filter(template => template.is_appendage);
              this.templates = response.data.filter(template => !template.is_appendage);
              const currentCompId = store.getters.getCurrentCompanyId;
              this.companyTemplates = this.templates.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
              this.companyAttachments = this.attachments.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
          }).catch(this.toastError);
      },
      getJobFunctions(){        
        this.jobFunctions = store.getters.getDepartments;
        this.jobDepartments = store.getters.getFunctions;
      },
      calculateTotalHours(e){
        let total = 0;
        this.schedule.forEach(hours => {
          const value = Number(hours);
          total += ((!hours || isNaN(value)) ? 0 : value);
        });
        total = total / 2;
        this.contractData.job_function.timetable = this.schedule.join(',');
        this.contractData.job_function.hours = total;
      },
      getSumBSN(bsn) {
        let a = parseInt(bsn[0]) * 9;
        let b = parseInt(bsn[1]) * 8;
        let c = parseInt(bsn[2]) * 7;
        let d = parseInt(bsn[3]) * 6;
        let e = parseInt(bsn[4]) * 5;
        let f = parseInt(bsn[5]) * 4;
        let g = parseInt(bsn[6]) * 3;
        let h = parseInt(bsn[7]) * 2;
        let i = parseInt(bsn[8]) * -1;

        let sum = a + b + c + d + e + f + g + h + i;
        return sum;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      validateBSN(userBSN) {
        let bsn = ("000000000" + userBSN.value).slice(-9);

        let valid = true;
        if (!userBSN.value || isNaN(userBSN.value) || Number(userBSN.value) == 0 || this.getSumBSN(bsn) % 11)
          valid = false;

        this.showValidity(userBSN, valid, true);
        return valid;
      },
      validateIBAN(userIBAN) {
        this.nawData.iban = this.nawData.iban.toUpperCase().replaceAll(/[^A-Z0-9]/g, '');
        let valid = userIBAN.value && IBAN.isValid(this.nawData.iban);
        this.showValidity(userIBAN, valid, true);
        return valid;
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },
      decodeTemplate(input, articleNumber = 0){
        let output = input;
        //Set variables
        const varMatches = output.matchAll(/##([A-Za-z0-9_]+?)##/gs);
        for(let match of varMatches){
          if(match[1] == 'artikel'){
            output = output.replace(match[0], "\u200b" + articleNumber );
            continue;
          }
          const value = this.dict[match[1]];
          if(!this.pellEditor)
            output = output = output.replace(match[0], (value === null || value === '' || value === undefined || value === 'Invalid date') ? ' [ NOG NIET BEPAALD ] ' : value);
          else
            output = output.replace(match[0], value === null ? '' : value);
        }
        //Set groups
        const grMatches = output.matchAll(/<([A-Za-z ]+?):([A-Za-z ]+?)>(.+?)<\/.+?>/gs);
        for(let match of grMatches){
          const field = this.dict[match[1]];
          const value = this.dict[match[2]];
          const inner = match[3];
          if(field == value || (field == null && value === false))
            output = output.replace(match[0], inner);
          else
            output = output.replace(match[0], '');
        }
        output = output.trim().replaceAll('\n', '<br/>');
        return output;
      },
      setArticles(){
        this.setDict();
        this.articles = [];
        const template = this.templates.find(template => template.id == this.contractData.template_id);
        let templateHeader = template.contents.replace(/<artikel>.+/gs, '');
        let templateFooter = template.contents.replace(templateHeader, '');
        const regex = /<artikel>(.+?)<\/artikel>/gs;
        const matches = template.contents.matchAll(regex);
        let i = 0;
        for(let match of matches){
          const matchText = match[1];
          if(matchText[0] == '<') continue;
          const nameString = matchText.match(/\*(.+?)\*/)[0];
          let text = matchText.replace(nameString, '');
          text = this.decodeTemplate(text, i + 1);
          const nameArray = nameString.slice(1, -1).split('##');
          this.articles.push({prefix: nameArray[0], index: (i + 1), name: nameArray[2], text: text, show: true});
          templateFooter = templateFooter.replace(match[0], '');
          i++;
        }
        this.templateHeader = templateHeader;
        this.templateFooter = templateFooter;
      },
      setDict(){
        const c = this.contractData;
        const n = (this.contractData.employee && this.contractData.employee.address) ? this.contractData.employee : this.nawData;
        const country = this.countries.find(country => country.id == (n.birth_country_id ? n.birth_country_id : 166));
        const compAddress = c.company && c.company.address ? JSON.parse(c.company.address) : this.companyData.address;
        const jobFunction = this.jobFunctions.find(func => func.id == c.job_function.debtor_function_id);
        const hasNAWInfo = this.pellEditor && this.isEditing && n && n.iban;
        const formatter = new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        this.dict = {
          werkgever_naam: c.company ? c.company.name : this.companyData.name,
          werkgever_adres: compAddress ? (compAddress.Street + ' ' + compAddress.HouseNumber) : '[ ADRES WERKGEVER ]',
          werkgever_postcode: compAddress ? compAddress.PostalCode : '[ POSTCODE WERKGEVER ]',
          werkgever_plaats: compAddress ? compAddress.City : '[ PLAATS WERKGEVER ]',
          werkgever_vertegenwoordiger_naam: c.company ? c.company.representative : this.companyData.representative,
          werknemer_voornaam: hasNAWInfo ? n.first_name : '[ VOORNAAM ]',
          werknemer_voorletters: hasNAWInfo ? (n.first_name[0] + '.') : '[ INITIAAL ]',
          werknemer_voorvoegsel: hasNAWInfo && n.prefix ? n.prefix : '[ TUSSENVOEGSEL ]',
          werknemer_achternaam: hasNAWInfo ? n.last_name : '[ ACHTERNAAM ]',
          werknemer_straat: hasNAWInfo ? n.address.street : '[ STRAAT ]',
          werknemer_huisnr: hasNAWInfo ? n.address.house_number : '[ HUISNUMMER ]',
          werknemer_toevoeg: hasNAWInfo ? n.address.prefix : '[ TOEVOEGING ]',
          werknemer_postcode: hasNAWInfo ? n.address.postal_code : '[ POSTCODE ]',
          werknemer_plaats: hasNAWInfo ? n.address.place : '[ WOONPLAATS ]',
          werknemer_geboortedatum: hasNAWInfo ? this.formatDate(n.birth_date, 'shortDate') : '[ GEBOORTEDATUM ]',
          werknemer_geboorteplaats: hasNAWInfo ? n.birth_place : '[ GEBOORTEPLAATS ]',
          werknemer_geboorteland: hasNAWInfo ? country.name : '[ GEBOORTELAND ]',
          startdatum: c.job_function.start_date ? this.formatDate(c.job_function.start_date, 'shortDate') : '[ STARTDATUM ]',
          einddatum: this.formatDate(c.job_function.fixed_term_expiration_date, 'shortDate'),
          uren: this.getHoursCleaned(c.job_function.hours),
          proeftijd_maand: c.job_function.probation_expiration_month,
          functie_naam: jobFunction ? jobFunction.name : (c.job_function.name ? c.job_function.name : '[ NOG NIET BEPAALD ]'),
          referentienummer: c.job_function.reference_number ? c.job_function.reference_number : '(geen referenties opgegeven)',
          salaris: c.job_function.salary ? formatter.format(c.job_function.salary) : 0,
          salaris_per: c.job_function.employment_type == 'hourly' ? 'uur' : (c.job_function.employment_type == 'internship' ? 'stageperiode' : 'maand'),
          artikel: '',
          onkosten: c.job_function.expenses_compensation ? formatter.format(c.job_function.expenses_compensation) : 0,
          reiskosten: c.job_function.travel_compensation ? formatter.format(c.job_function.travel_compensation) : 0,
          reiskosten_per: c.job_function.travel_compensation_type == 'distance' ? 'kilometer' : 'maand',
          telefoon: c.job_function.phone_compensation ? formatter.format(c.job_function.phone_compensation) : 0,
          contract_plaats: c.generate_place,
          contract_datum: c.generate_date ? this.formatDate(c.generate_date, 'shortDate') : moment().format('DD-MM-YYYY'),
          werknemer_iban: hasNAWInfo ? n.iban : '[ IBAN ]',
          werknemer_email: c.employee.email,
          werknemer_bsnnummer: hasNAWInfo ? n.bsn_number : '[ BSN-NUMMER ]',
          werknemer_telefoonnr: hasNAWInfo ? n.phone_number : '[ TELEFOONNUMMER ]',
          contracttype: c.job_function.fixed_term,
          'Onbepaalde tijd': 0,
          'Bepaalde tijd': 1,
          vakkracht: c.job_function.skilled_worker,
          'ja': 1,
          'nee': 0,
          dienstverband: c.job_function.hours < 36,
          'Fulltime': false,
          'Parttime': true,
          proeftijd: c.job_function.probation,
          loonheffingskorting: c.job_function.tax_compensation == 1,
          handtekening_werkgever: '##handtekening_werkgever##',
          handtekening_werknemer: '##handtekening_werknemer##',
          'nieuwe pagina': ' nieuwe pagina '
        }
      },

      /*
       * Pell editor
       */
      initPell(){
        if(this.pellEditor != null) return;
        const pellElement = document.getElementById('pell');
        pellElement.innerHTML = '';
        this.$nextTick(() => {
          this.pellEditor = pell.init({
            element: pellElement,
            onChange: html => { this.contractData.document = html; },
            actions: [ 'bold', 'italic', 'underline', 'heading1', 'heading2', 'paragraph', 'olist', 'ulist' ]
          });
        });
      },
      setTemplate(){
        if(!this.shouldGenerateContract){
          // NOODOPLOSSING - GAAT SOMS OM ONBEKENDE REDEN FOUT IN DE BACK-END
          const naw = (this.contractData.employee && this.contractData.employee.address) ? this.contractData.employee : this.nawData;
          if(naw){
            this.contractData.document = this.contractData.document.replaceAll("[ BSN-NUMMER ]", naw.bsn_number).replaceAll("[ TELEFOONNUMMER ]", naw.phone_number);
          }
          // EINDE NOODOPLOSSING
          this.pellEditor.content.innerHTML = this.contractData.document;
          return;
        }
        let text = this.decodeTemplate(this.templateHeader);
        text += '<br>';
        let index = 1;
        this.articles.forEach(article => {
          if(article.show){
            text += '<br>';
            text += ('<b>' + article.prefix + ' ' + article.index + ' ' + article.name + '</b>');
            text += '<br>';
            text += article.text.replaceAll(/\u200b\d+/gm, index);
            text += '<br>';
            index++;
          }
        });
        text += '<br>';
        text += this.decodeTemplate(this.templateFooter);
        this.contractData.document = text;
        if(this.pellEditor){
          this.pellEditor.content.innerHTML = text;
        }
      },
      setArticleIndexes(e){
        this.refreshContract();
        let index = 1;
        this.articles.forEach(article => {
          if(article.show){
            article.index = index;
            article.text = article.text.replaceAll(/\u200b\d+/gm, "\u200b" + index);
            index++;
          }
          else{
            article.index = '-';
          }
        })
      },

      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
      syncJobFunctions(){
        this.getJobFunctions();
        const currentCompId = store.getters.getCurrentCompanyId;
        this.companyTemplates = this.templates.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
        this.companyAttachments = this.attachments.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
        this.getTemplates();
      },
      setProfessionData(data, href){
        console.log(data);
        for(let field in data){
          if(data[field] !== null)
            this.contractData[field] = data[field];
        }
        if(this.contractData.job_function){
          let jobfunction = this.contractData.job_function;
          let travelCompAsNumber = Number(jobfunction.travel_compensation);
          if(jobfunction.travel_compensation === null)
            jobfunction.travel_compensation_type = 'none';
          else if(travelCompAsNumber && travelCompAsNumber > 0 && travelCompAsNumber < 0.25)
            jobfunction.travel_compensation_type = 'distance';
          else
            jobfunction.travel_compensation_type = 'monthly';
          jobfunction.additional_compensations = (jobfunction.travel_compensation_type != 'none' || jobfunction.phone_compensation || jobfunction.expenses_compensation) ? 1 : 0;
        }
        this.oldContractData = JSON.parse(JSON.stringify(this.contractData));
        if(this.oldContractData.job_function.employment_type == "fulltime" && this.oldContractData.job_function.hours == 0){
          this.oldContractData.job_function.employment_type = "hourly";
        }
        this.schedule = this.contractData.job_function.timetable ? this.contractData.job_function.timetable.split(',') : ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'];
        this.$refs.editcontract.schedule = this.schedule;
        this.$refs.newcontract.schedule = this.schedule;
        this.calculateTotalHours(null);
        if(href.includes("contract")){
          this.shouldGenerateContract = false;
        }
      },
    },
    mounted() {
      const href = window.location.href;
      const newcontract = this.$refs.newcontract;
      const editnaw = this.$refs.editnaw;
      const editcontract = this.$refs.editcontract;
      if(href.includes("personalia")){
        editnaw.isLoading = true;
        editnaw.isEditing = true;
      }
      if(href.includes("new-employee")){
        this.formSection = 1;
        newcontract.isEditing = false;
        this.isReady = true;
      }

      window.setTimeout(() => {
        //Get countries and nationalities
        this.loadData();
        this.getTemplates();
        this.getJobFunctions();
        this.isReady = true;

        const setNAW = function(response, parent, ref){
          ref.isEditing = response.data.gender ? true : false;
          for(let field in response.data){
            if(response.data[field] !== null)
              parent.nawData[field] = response.data[field];
          }
          parent.nawData.files = [null, null, null];
          if(response.data.files){
            ref.docTypeOne = response.data.files[0];
            ref.docTypeTwo = response.data.files[2] ? response.data.files[1] : null;
            ref.docTypeThree = response.data.files[2] ? response.data.files[2] : (response.data.files[1] ? response.data.files[1] : null);
            ref.hasFrontAndBack = response.data.files[1] && response.data.files[2];
          }
          parent.nawData.new_identity_files = 0;
          ref.isLoading = false;
        }
        
        if( href.includes("edit-employee") ){
          this.isEditing = true;
          const splitHref = href.split('/');
          const id = splitHref[splitHref.length - 1];
          const hasNMBRSLink = id > 99999;
          newcontract.sendDirectlyToNMBRS = hasNMBRSLink;
          editnaw.sendDirectlyToNMBRS = hasNMBRSLink;
          editcontract.sendDirectlyToNMBRS = hasNMBRSLink;
          if(href.includes("main")){
            this.formSection = 1;
            newcontract.isEditing = !hasNMBRSLink;
            newcontract.currentEmployeeStep = 1;
            newcontract.isLoading = 1;
            if(hasNMBRSLink){
              employeeService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                setNAW(response, this, editnaw);
                this.nawData.effective_date = moment().format('YYYY-MM-DD');
                this.contractData = response.data.contract;
                this.contractData.employee = {...this.nawData};
                this.schedule = this.contractData.job_function.timetable.split(',');
                this.contractData.attachments = [];
                if(this.contractData.job_function.employment_type == "fulltime" && this.contractData.job_function.hours == 0){
                  this.contractData.job_function.employment_type = "hourly";
                }
                newcontract.schedule = this.schedule;
                newcontract.isLoading = 0;
                if(this.contractData.job_function.debtor_function_id){
                  let selJobFunc = this.jobFunctions.find(func => func.name == this.contractData.job_function.name);
                  if(selJobFunc) selJobFunc.id = this.contractData.job_function.debtor_function_id;
                }
                if(this.contractData.job_function.debtor_department_id){
                  let selJobDept = this.jobDepartments.find(func => func.name == this.contractData.job_function.department);
                  if(selJobDept) selJobDept.id = this.contractData.job_function.debtor_department_id;
                }
              }).catch(this.toastError);
            }
            else{
              contractService.show(id).then(response => {
                  this.contractData = response.data;
                  if(!response.data.attachments){
                    this.contractData.attachments = [];
                  }
                  this.schedule = this.contractData.job_function.timetable.split(',');
                  editcontract.schedule = this.schedule;
                  newcontract.isLoading = 0;
                  newcontract.schedule = this.schedule;
                  newcontract.workHomeType = this.contractData.job_function.has_wage_tax_credit ? (this.contractData.job_function.total_work_days_at_home ? "calculate" : "monthly") : "none";
              }).catch(this.toastError);
            }
          }
          else if(href.includes("personalia") || href.includes("naw")){
            this.formSection = 2;
            editnaw.currentNAWStep = 1;
            this.isEmployeeFillingInNAW = href.includes("personalia");
            if(hasNMBRSLink){
              employeeService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                setNAW(response, this, editnaw);
                this.nawData.effective_date = moment().format('YYYY-MM-DD');
                this.contractData = response.data.contract;
                editnaw.isLoading = false;
              }).catch(this.toastError);
            }
            else{
              employeeService.show(id).then(response => setNAW(response, this, editnaw)).catch(this.toastError);
            }
          }
          else{
            if(hasNMBRSLink){
              this.formSection = 2;
              editnaw.isEditing = true;
              contractService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                  if(!this.$refs.editcontract) return;
                  this.setProfessionData(response.data, href);
                  this.contractData.effective_date = moment().format('YYYY-MM-DD');
                  employeeService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                      for(let field in response.data){
                        if(response.data[field] !== null)
                          this.nawData[field] = response.data[field];
                      }
                      this.oldContractData.job_function.timetable = this.schedule.join(',') + '';
                      if(this.oldContractData.job_function.employment_type == "fulltime" && this.oldContractData.job_function.hours == 0){
                        this.oldContractData.job_function.employment_type = "hourly";
                      }
                      if(href.includes("contract")){
                        this.contractData.send_sign_request = true;
                        this.contractData.employer_id = null;
                        this.contractData.company_id = store.getters.getCurrentCompanyId;
                        this.$nextTick(this.initPell);
                      }
                      if(href.includes("profession")){
                        this.contractData.job_function.start_date = moment().format('YYYY-MM-DD');
                        this.contractData.job_function.fixed_term_expiration_date = this.contractData.job_function.fixed_term ? moment().add(1, 'Y').subtract(1, 'days').format('YYYY-MM-DD') : null;
                        if(this.contractData.job_function.employment_type == "fulltime" && this.contractData.job_function.hours == 0){
                          this.contractData.job_function.employment_type = "hourly";
                        }
                        if(this.contractData.job_function.debtor_function_id){
                          let selJobFunc = this.jobFunctions.find(func => func.name == this.contractData.job_function.name);
                          if(selJobFunc) selJobFunc.id = this.contractData.job_function.debtor_function_id;
                        }
                        if(this.contractData.job_function.debtor_department_id){
                          let selJobDept = this.jobDepartments.find(func => func.name == this.contractData.job_function.department);
                          if(selJobDept) selJobDept.id = this.contractData.job_function.debtor_department_id;
                        }
                      }
                  }).catch(this.toastError);
              }).catch(this.toastError);
            }
            else{
              contractService.show(id).then(response => {
                  this.setProfessionData(response.data, href);
                  employeeService.show(response.data.employee_id).then(response => {
                      for(let field in response.data){
                        if(response.data[field] !== null)
                          this.nawData[field] = response.data[field];
                      }
                      this.oldContractData.job_function.timetable = this.schedule.join(',') + '';
                      if(href.includes("contract")) this.$nextTick(this.initPell);
                  }).catch(this.toastError);
              }).catch(this.toastError);
            }

          }
          
          if(href.includes("profession")){
            this.formSection = 3;
            editcontract.currentContractStep = 1;
            editcontract.facOnly = true;
            editcontract.professionEdit = true;
            editcontract.isEditing = true;
            editcontract.sendDirectlyToNMBRS = true;
            this.editingProfession = true;
          }
          if(href.includes("salary")){
            this.formSection = 3;
            editcontract.salaryOnly= true;
            editcontract.currentContractStep = 1;
            editcontract.facOnly = true;
            editcontract.isEditing = true;
          }
          if(href.includes("contract")){
            this.formSection = 3;
            editcontract.isEditing = true;
            editcontract.currentContractStep = 1;
            companyService.show(store.getters.getCurrentCompanyId).then(response => this.companyData = response.data).catch(e => console.log(e));
          }
        }
        else{
          let companyId = store.getters.getCurrentCompanyId;
          this.nawData.company_id = companyId;
          if(!companyId){
            window.location.reload();
          }
        }

        
      }, 100);
    }
  }
</script>