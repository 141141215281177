<template>
  <section v-if="visible">
    <div class="row">

      <div class="col-7 p-4 border-right">
        <div v-if="currentEmployeeStep <= maxEmployeeStep">
          <p><b>{{ currentEmployeeStep }}</b>/{{ maxEmployeeStep + ' ' + $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</p>
          <h1>{{ $t('employee.step_' + currentEmployeeStep) }}</h1>
        </div>



        <div v-show="currentEmployeeStep == 1" id="step-1" style="min-height: 393px">
          <p>{{ $t('employee.text_1') }}</p>
          <div v-if="isLoading">
            <i class="fa fa-spin fa-spinner"></i>&ensp;{{ $t('form.please_wait') }}
          </div>
          <template v-else>
          <label for="first-name">{{ $t('form.first_name') }}</label>
          <input type="text" id="first-name" v-model="contractData.employee.first_name" @change="isValid" />
          <label for="prefx-name">{{ $t('form.prefix') }}</label>
          <input type="text" id="prefx-name" class="optional" v-model="contractData.employee.prefix" />
          <label for="last-name">{{ $t('form.last_name') }}</label>
          <input type="text" id="last-name" v-model="contractData.employee.last_name" @change="isValid" />
          <label for="email">{{ $t('form.email') }}</label>
          <input type="email" id="email" v-model="contractData.employee.email" @change="isValid" />
          </template>
        </div>



        <div v-show="currentEmployeeStep == 2" id="step-2">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_2') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 2)"><legend>{{ getInfoText(1, 2) }}</legend></i>
          </div>
          <fieldset :data-field="contractData.job_function.employment_type">
            <label for="wage-1" class="inputgroup">
              <input type="radio" id="wage-1" name="type-of-employment" value="fulltime" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_fulltime')}}</span>
            </label>
            <label for="hourly" class="inputgroup">
              <input type="radio" id="hourly" name="type-of-employment" value="hourly" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_hourly')}}</span>
            </label>
            <label for="internship" class="inputgroup">
              <input type="radio" id="internship" name="type-of-employment" value="internship" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_internship')}}</span>
            </label>
          </fieldset>
          <div v-if="contractData.job_function.employment_type == 'hourly'" class="row auto-layout mt-2">
            <label for="hourly-wage" class="w-50"> {{$t('employee.gross_wage_hour')}}<br> <sup>{{$t(currentEmployeeStep == 2 && gwiPercentage ? 'employee.gross_wage_incl' : 'employee.gross_wage_excl')}}</sup> </label>
            <div class="input-group w-50">
              <span>€</span> <input type="number" id="hourly-wage" v-model="contractData.job_function.salary" step="0.01" @change="isValid" />
            </div>
          </div>
          <div v-else-if="contractData.job_function.employment_type == 'internship'" class="row auto-layout mt-2">
            <label for="internship-wage" class="w-50"> {{$t('employee.internship_wage')}}<br><sup>{{$t('employee.per_month')}}</sup> </label>
            <div class="input-group w-50">
              <span>€</span> <input type="number" id="internship-wage" v-model="contractData.job_function.salary" step="0.01" @change="isValid" />
            </div>
          </div>
          <div v-else-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'" class="auto-layout mt-2">
            <div class="row auto-layout">
              <label for="pt-wage" class="w-50">
                {{$t('employee.gross_wage_month')}}
              </label>
              <div class="input-group w-50">
                <span>€</span> <input type="number" step="0.01" id="pt-wage" v-model="contractData.job_function.salary" @change="isValid" />
              </div>
            </div>
            <div class="row">
              <label>{{ $t('employee.roster_1') }}</label>
              <div>
                <div class="row employee-schedule">
                  <small class="col"></small>
                  <div class="col" v-for="weekday in weekdays" :key="weekday">{{weekday}}</div>
                </div>
                <div class="row employee-schedule">
                  <small class="col">Week 1</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[n-1]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[4+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
                <div class="row employee-schedule mt-1">
                  <small class="col">Week 2</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[6+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[11+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <label>{{ $t('employee.hours') }}</label>
              <div type="number" class="optional-disabled">{{getHoursCleaned(contractData.job_function.hours)}}</div>
            </div>
          </div>
          <div class="row" v-if="currentEmployeeStep == 2 && contractData.job_function.employment_type && showSkilledWorker">
            <label class="col-auto" :style="{width: '30%'}">{{ $t('employee.profession') }}</label>
            <fieldset class="col btn-group optional" :style="{'padding-left': '0.4rem', 'padding-right': '2.5rem'}" :data-field="contractData.job_function.skilled_worker">
              <input id='profession1' type="radio" name="profession" class="btn-check" :value="1" v-model="contractData.job_function.skilled_worker" />
              <label for="profession1" class="btn btn-sm">{{$t('employee.profession_1')}}</label>
              <input id='profession2' type="radio" name="profession" class="btn-check" :value="0" v-model="contractData.job_function.skilled_worker" />
              <label for="profession2" class="btn btn-sm">{{$t('employee.profession_2')}}</label>
              <input id='profession3' type="radio" name="profession" class="btn-check" :value="null" v-model="contractData.job_function.skilled_worker" />
              <label for="profession3" class="btn btn-sm">{{$t('employee.profession_3')}}</label>
            </fieldset>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 3" id="step-3">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_3') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 3)"><legend>{{ getInfoText(1, 3) }}</legend></i>
          </div>
          <div class="auto-layout">
            <div class="row">
              <label for="job-title">{{ $t('employee.job_title') }}</label>
              <select id="job-title" class="optional" v-model="contractData.job_function.debtor_function_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="func in jobFunctions" :key="func.id" :value="func.id" @click="contractData.job_function.name = func.name">{{ func.name }}</option>
              </select>
            </div>
            <div class="row" v-if="currentEmployeeStep == 3 && showReferenceAlt">
              <label for="reference_number">{{ $t('employee.reference_number') }}</label>
              <input type="text" id="reference_number" class="optional" v-model="contractData.job_function.reference_number" />
            </div>
            <div class="row">
              <label for="department">{{ $t('employee.department') }}</label>
              <select id="department" class="optional" v-model="contractData.job_function.debtor_department_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="dep in jobDepartments" :key="dep.id" :value="dep.id" @click="contractData.job_function.department = dep.name">{{ dep.name }}</option>
              </select>
            </div>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 1" id="step-5">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_4') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 4)"><legend>{{ getInfoText(1, 4) }}</legend></i>
          </div>
          <fieldset :data-field="contractData.job_function.fixed_term">
            <label for="contract-1" class="inputgroup">
              <input type="radio" id="contract-1" name="type-of-contract" :value="1" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <span>{{$t('employee.contract_limited')}}</span>
            </label>
            <label for="contract-2" class="inputgroup">
              <input type="radio" id="contract-2" name="type-of-contract" :value="0" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <span>{{$t('employee.contract_unlimited')}}</span>
            </label>
          </fieldset>
          <div v-if="contractData.job_function.fixed_term !== null" class="ps-4 mt-2">
            <div class="row">
              <div class="col-6">
                <sup>{{$t('employee.contract_date_start')}}</sup>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <input type="date" v-model="contractData.job_function.start_date" @change="isValid($event); setProbationTime()" />
              </div>
            </div>
            <div class="row" v-show="contractData.job_function.fixed_term">
              <div class="col-6">
                <sup>{{$t('employee.contract_date_end')}}</sup>
              </div>
            </div>
            <div class="row" v-if="contractData.job_function.fixed_term">
              <div class="col-6">
                <select @change="setContractEndTime" v-model="contractData.job_function.fixed_term_expiration_month">
                  <option value="0"> - </option>
                  <option v-for="n in 12" :value="n" :key="n">{{ n + ' ' + $tc('form.month', n) }}</option>
                </select>
              </div>
              <div class="col-6">
                <input type="date" v-model="contractData.job_function.fixed_term_expiration_date" @change="isValid" />
              </div>
            </div>
            <div class="d-flex auto-layout" v-if="isValidProbationOption(1)">
              <label class="w-75">{{ $t('employee.contract_has_trial') }}</label>
              <fieldset class="btn-group w-25" :data-field="contractData.job_function.probation">
                <input id='contract-probation-1' type="radio" name="trial-yes" class="btn-check" :value="1" v-model="contractData.job_function.probation" @change="isValid" />
                <label for="contract-probation-1" class="btn btn-sm">{{$t('form.yes')}}</label>
                <input id='contract-probation-2' type="radio" name="trial-no" class="btn-check" :value="0" v-model="contractData.job_function.probation" @change="isValid" />
                <label for="contract-probation-2" class="btn btn-sm">{{$t('form.no')}}</label>
              </fieldset>
            </div>
            <div v-if="contractData.job_function.probation" class="ps-0 mt-2">
              <div class="row">
                <label for="prob-enddate" class="mt-2">{{ $t('employee.prob_end_date') }}</label>
                <div class="d-flex gap-2" style="width: calc(100% - 195px)">
                  <select class="w-50" @change="setProbationTime" v-model="contractData.job_function.probation_expiration_month">
                    <option value="0"> - </option>
                    <option value="1" v-if="isValidProbationOption(1)">{{ 1 + ' ' + $tc('form.month', 1) }}</option>
                    <option value="2" v-if="isValidProbationOption(2)">{{ 2 + ' ' + $tc('form.month', 2) }}</option>
                  </select>
                  <input id="prob-enddate" type="date" v-model="contractData.job_function.probation_expiration_date" class="w-50" @change="isValid" />
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <label>{{ $t('employee.contract_upload_now') }}</label>
              <fieldset class="btn-group" :data-field="contractData.generate_contract">
                <input id='contract-upload-1' type="radio" name="contract-upload" class="btn-check" :value="0" v-model="contractData.generate_contract" @change="isValid" />
                <label for="contract-upload-1" class="btn btn-sm">{{$t('employee.direct_upload')}}</label>
                <input id='contract-upload-2' type="radio" name="contract-upload" class="btn-check" :value="1" v-model="contractData.generate_contract" @change="isValid" />
                <label for="contract-upload-2" class="btn btn-sm">{{$t('employee.generate_contract')}}</label>
              </fieldset>
            </div>
            <UploadModule v-if="contractData.generate_contract === 0" :model="(contractData.files ? contractData.files[0] : contractData.document)"
              title="Heb je al een contract van deze werknemer" description="Upload hem door hem in dat vak te slepen of <u>klik hier</u> om hem te zoeken"
              type="pdf" :owner="'contractFile'" ref="contractFileUpload" @addFile="(e) => addContractFile(e, 0)" @removeFile="removeContractFile" />
            <template v-else-if="contractData.generate_contract === 1">
              <div class="row">
                <label for="template">{{ $t('employee.template') }}</label>
                <div>
                  <select id="template" v-model="contractData.template_id" @change="isValid">
                    <option :value="null" disabled>{{ $t('form.select') }}</option>
                    <option v-for="template in companyTemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row" v-show="attachments.length > 0">
                <label>{{ $t('employee.attachments') }}</label>
                <div>
                  <div v-for="field, index in contractData.attachments" :key="index" class="responsive-list">
                    <select v-model="contractData.attachments[index]" @change="isValid">
                      <option value="" disabled>{{ $t('form.select') }}</option>
                      <option v-for="attachment in companyAttachments" :key="attachment.id" :value="attachment.id">{{ attachment.name }}</option>
                    </select>
                    <button type="button" class="btn" @click="responsiveListControl(contractData.attachments, index)"> <i class="fa fa-close text-danger"></i> </button>
                  </div>
                  <p v-show="contractData.attachments.length == 0">{{ $t('form.no_selected', [$t('navigation.attachments')]) }}</p>
                </div>
              </div>
              <div class="d-flex justify-content-center my-2" v-if="attachments.length > 0">
                <button type="button" class="btn action-button w-75" @click="responsiveListControl(contractData.attachments)"> {{ $t('employee.add_attachment') }} </button>
              </div>
              <div class="row">
                <label for="contractdate_early">{{ $t('employee.contract_date') }}</label>
                <div> <input type="date" class="optional" id="contractdate_early" v-model="contractData.generate_date" @change="isValid" /> </div>
              </div>
              <div class="row">
                <label for="contractplace_early">{{ $t('employee.contract_place') }}</label>
                <div> <input type="text" class="optional" id="contractplace_early" v-model="contractData.generate_place" @change="isValid" /> </div>
              </div>
            </template>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 4" id="step-4">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_5') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 5)"><legend>{{ getInfoText(1, 5) }}</legend></i>
          </div>
          <div class="auto-layout">
            <div class="row comp-travel">
              <label class="pt-3">{{$t('employee.comp_travel')}}</label>
              <fieldset class="btn-group" :data-field="contractData.job_function.travel_compensation_type">
                <input id='tc1' type="radio" name="tc_type" class="btn-check" value="none" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                <input id='tc2' type="radio" name="tc_type" class="btn-check" value="distance" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = '0.23'" />
                <label for="tc2" class="btn btn-sm">{{$t('employee.travel_compensation_type_2')}}</label>
                <input id='tc3' type="radio" name="tc_type" class="btn-check" value="monthly" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc3" class="btn btn-sm">{{$t('employee.travel_compensation_type_3')}}</label>
                <input id='tc4' type="radio" name="tc_type" class="btn-check" value="calculate" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc4" class="btn btn-sm">{{$t('employee.travel_compensation_type_4')}}</label>
              </fieldset>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row" v-if="contractData.job_function.travel_compensation_type == 'monthly' || contractData.job_function.travel_compensation_type == 'distance'">
              <label></label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-1" type="number" class="optional" v-model="contractData.job_function.travel_compensation" step="0.01" @change="isValid" />
              </div>
              <span v-show="contractData.job_function.travel_compensation_type == 'monthly'">{{ $t('employee.per_month') }}</span>
              <span v-show="contractData.job_function.travel_compensation_type == 'distance'">per km</span>
            </div>
            <template v-else-if="contractData.job_function.travel_compensation_type == 'calculate'">
              <div class="row">
                <label class="pe-0">{{$t('employee.travel_compensation_days')}}</label>
                <div> <input id="travelcomp-2" type="number" v-model="contractData.job_function.travel_compensation_days" @change="isValid($event); calcTravelCompensation()" /> </div>
              </div>
              <div class="row">
                <label>{{$t('employee.travel_compensation_distance')}}</label>
                <div class="input-group">
                  <input id="travelcomp-3" type="number" v-model="contractData.job_function.travel_compensation_distance" step="0.01" @change="isValid($event); calcTravelCompensation()" />
                  <span class="w-25 p-2">km</span>
                </div>
              </div>
              <div class="ms-1 ps-2">
                {{ $t('employee.travel_compensation_calculated') + (contractData.job_function.travel_compensation ? contractData.job_function.travel_compensation.toFixed(2) : '-') }}
              </div>
            </template>
            <br>
          </div>
          <div class="auto-layout">
            <div class="row comp-travel">
              <label class="pt-1">{{$t('employee.work_home_compensation')}}</label>
              <fieldset class="btn-group" :data-field="workHomeType">
                <input id='whc1' type="radio" name="whc_type" class="btn-check" value="none" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(false)" />
                <label for="whc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                <input id='whc2' type="radio" name="whc_type" class="btn-check" value="monthly" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                <label for="whc2" class="btn btn-sm">{{$t('employee.work_home_compensation_type_1')}}</label>
                <input id='whc3' type="radio" name="whc_type" class="btn-check" value="calculate" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                <label for="whc3" class="btn btn-sm">{{$t('employee.work_home_compensation_type_2')}}</label>
              </fieldset>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row" v-if="workHomeType == 'monthly'">
              <label></label>
              <div class="input-group">
                <span>€</span> <input id="wcomp-1" type="number" v-model="contractData.job_function.work_days_at_home_compensation" step="0.01" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
            <template v-else-if="workHomeType == 'calculate'">
              <div class="row">
                <label class="pe-0">{{$t('employee.work_home_compensation_days')}}</label>
                <div> <input id="wcomp-2" type="number" v-model="contractData.job_function.total_work_days_at_home" @change="isValid($event); calcWorkHomeCompensation()" /> </div>
              </div>
              <div class="ms-1 ps-2">
                {{ $t('employee.work_home_compensation_calculated') + (contractData.job_function.work_days_at_home_compensation ? Number(contractData.job_function.work_days_at_home_compensation).toFixed(2) : '-') }}
              </div>
            </template>
            <br>
          </div>
          <div class="auto-layout-3">
            <div class="row">
              <label for="travelcomp-2">{{$t('employee.comp_expenses')}}</label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-2" type="number" class="optional" step="0.01" v-model="contractData.job_function.expenses_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row">
              <label for="travelcomp-3">{{$t('employee.comp_phone')}}</label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-3" type="number" class="optional" step="0.01" v-model="contractData.job_function.phone_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 2" id="step-8">
          <h5>{{ $t('employee.choose_articles') }}</h5>
          <div v-for="article, index in articles" :key="index" class="">
            <input type="checkbox" v-model="article.show" @change="setArticleIndexes" :id="'article-'+index">
            <label :for="'article-'+index" class="fw-bold d-inline">{{ article.prefix + ' ' + article.index + ' ' + article.name }}</label>
            <p v-show="article.show" v-html="article.text"></p>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 3" id="step-9">
          <h5>{{ $t('employee.review_contract') }}</h5>
          <div id="pell" class="pell" />
          <div v-if="contractData.attachments && contractData.attachments.length > 0">
            <h5 class="mt-4">{{ $tc('navigation.attachments', 2) }}</h5>
            <p v-for="(att, attIndex) in contractData.attachments" :key="attIndex" v-html="showAttachment(att)"></p>
          </div>
        </div>



        <div v-show="currentEmployeeStep == 6" id="step-6">
          <template v-if="sendDirectlyToNMBRS">
            <h1>Contract medewerker gewijzigd</h1>
            <p>Voer nu de NAW-gegevens in van de medewerker</p>
          </template>
          <template v-else>
            <h1>{{ $t('employee.employee_added') }}</h1>
            <h2>{{ $t('employee.naw_title') }}</h2>
            <p>{{ $t('employee.naw_text') }}</p>
          </template>
        </div>
        <div v-show="currentEmployeeStep > 6" id="step-7">
          <h1>{{ $t('employee.mail_employee') }}</h1>
          <label for="to-line">{{ $t('employee.mail_to') }}</label>
          <input type="email" id="to-line" v-model="mail.email_to" @change="isValid" />
          <label for="subject">{{ $t('employee.mail_subject') }}</label>
          <input type="text" id="subject" v-model="mail.subject" @change="isValid" />
          <label for="message">{{ $t('form.message') }}</label>
          <textarea id="message" :rows="setTextAreaRows(mail.email_body)" v-model="mail.email_body"></textarea>
        </div>
      </div>



      <div class="col-5 p-4">
        <div class="position-sticky" v-show="!sendingCorrections">
          <h2>{{ $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_1') }}</li>
            <li :class="getListClass(2)">{{ $t('employee.step_2') }}</li>
            <li :class="getListClass(3)">{{ $t('employee.step_3') }}</li>
            <li :class="getListClass(4)">{{ $t('employee.step_4') }}</li>
            <li :class="getListClass(5)">{{ $t('employee.step_5') }}</li>
            <li :class="getListClass(6)" v-if="!isEditing">{{ $t('employee.step_6') }}</li>
          </ol>
        </div>
      </div>
    </div>



    <div class="row justify-content-between">
      <div class="col-6 text-start">
        <button type="button" class="btn px-5 secondary-button" @click="back" v-if="currentEmployeeStep > 1 || sendingCorrections">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-end" v-if="contractData.generate_contract === 1 && currentEmployeeStep == maxEmployeeStep">
        <button type="button" v-if="currentEmployeeContractStep == maxContractStep" class="btn px-5 action-button mx-3" @click="saveSection('download')" :disabled="isSaving"> 
          <i class="fa" :class="{'fa-download': !isSaving, 'fa-spinner fa-spin': isSaving}"></i>&ensp;{{ $t('overview.download_pdf') }} 
        </button>
        <button type="button" class="btn px-5 btn-success" @click="saveSection(false)" v-if="currentEmployeeContractStep == maxContractStep && isEditing" :disabled="isSaving">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 action-button" v-else @click="setNextContract" :disabled="isSaving">{{ $t('form.next') }}</button>
      </div>
      <div class="col-6 text-end" v-else>
        <button type="button" class="btn px-5 btn-success" v-if="currentEmployeeStep > (maxEmployeeStep + 1)" @click="sendMailForNAW" :disabled="sendingMail">{{ $t('form.send') }}</button>
        <button type="button" class="btn px-5 btn-success" @click="saveSection(false)" v-else-if="isEditing && currentEmployeeStep == maxEmployeeStep" :disabled="isSaving">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="currentEmployeeStep <= maxEmployeeStep" :disabled="isLoading">{{ $t('form.next') }}</button>
        <div class="d-flex gap-2 justify-content-end" v-else>
          <button type="button" class="btn px-5 secondary-button" @click="setMailInfo" v-if="!sendDirectlyToNMBRS">{{ $t('employee.mail_employee') }}</button>
          <button type="button" class="btn px-5 action-button" @click="setNextFormStep">{{ $t('employee.fillself') }}</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import UploadModule from '@/components/UploadModule'
  import contractService from '@/services/ContractService'
  import employeeService from '@/services/EmployeeService'
  import companyService from '@/services/CompanyService'
  import fileService from '@/services/FileService'
  import moment from 'moment'
  import store from '@/store/user'
  import router from '@/router'
  import pellCSS from 'pell/dist/pell.min.css'
  export default {
    name: 'NewContract',
    props: ['visible', 'contractObject', 'jobDepartments', 'jobFunctions', 'companyTemplates', 'attachments', 'companyAttachments', 'articles', 'infoTexts'],
    components: {
      UploadModule
    },
    computed: {
      showSkilledWorker() { return window.currentCustomer && window.currentCustomer.use_skilled_worker },
      gwiPercentage() { return (window.currentCustomer && window.currentCustomer.holiday_pay_hourly_percentage) ? window.currentCustomer.holiday_pay_hourly_percentage : false },
      showReferenceAlt() { return window.currentCustomer && window.currentCustomer.use_refrence },
      contractData: { get() { return this.contractObject } },
    },
    data() {
      return{
        isEditing: false,
        currentEmployeeStep: 1,
        maxEmployeeStep: 5,
        maxContractStep: 3,
        currentEmployeeContractStep: 1,
        sendingCorrections: false,
        templateHeader: "",
        templateFooter: "",
        countries: [],
        nationalities: [],
        templates: [],
        documentTypes: [],
        shouldGenerateContract: true,
        sendingMail: false,
        isSaving: false,
        sendDirectlyToNMBRS: false,
        workHomeType: "none",
        weekdays: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
        mail: { email_to: "", subject: "", email_body: "" },
        schedule: ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'],
        dict: {},
        pellEditor: null
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast'))
                  validityCheck = date > Date.now();
                else
                  validityCheck = date < Date.now();
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const step = this.currentEmployeeStep;
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        return isValid;
      },
      showAttachment(id){
        const attachment = this.attachments.find(att => att.id == id);
        return attachment ? attachment.contents.replaceAll("\n", "<br />") : "-";
      },
      back(){
        if(this.sendingCorrections){
          this.setNextFormStep();
        }
        else if(this.currentEmployeeContractStep == 1 || this.currentEmployeeStep > 5){
          this.currentEmployeeStep--;
        }
        else{
          this.currentEmployeeContractStep--;
        }
      },
      setNext(){
        if(!this.validate()) return;

          if(this.currentEmployeeStep == 4){
            this.$parent.companyData = {};
            companyService.show(store.getters.getCurrentCompanyId).then(response => this.$parent.companyData = response.data).catch(e => console.log(e));
          }
          if(this.currentEmployeeStep == 1 && !this.isEditing){
            let timetable = store.getters.getDefaultSchedule;
            if(timetable)
            {  
              timetable = timetable.replaceAll('.00', '');
              this.schedule = timetable.split(',');
            }
            this.contractData.job_function.timetable = timetable;
            this.calculateTotalHours(null);
            this.$nextTick(this.$parent.initPell);
          }
          else if(this.currentEmployeeStep == 1 && this.isEditing){
            this.calculateTotalHours(null);
            this.$nextTick(this.$parent.initPell);
          }
          //Pre-fill some NAW data
          if(this.currentEmployeeStep == this.maxEmployeeStep){
            const naw = this.$parent.nawData;
            naw.first_name = this.contractData.employee.first_name;
            naw.prefix = this.contractData.employee.prefix;
            naw.last_name = this.contractData.employee.last_name;
            if(!naw.company_id) naw.company_id = store.getters.getCurrentCompanyId;
            this.$parent.contractData.company_id = naw.company_id;
            this.$parent.contractData.job_function.company_id = this.$parent.contractData.company_id;
            naw.email = this.$parent.contractData.employee.email;
            this.mail.email_to = this.$parent.contractData.employee.email;
            naw.files = [null, null, null];
            this.sendContractData(1);
          }
          this.currentEmployeeStep++;

        window.scrollTo(0, 0);
      },
      setNextContract(){
        if(!this.contractData.template_id){
          alert(this.$t('errors.choose_template'));
          document.getElementById('template').classList.add('is-invalid');
          return;
        }
        if(this.currentEmployeeContractStep == 3){
          this.setNext();
          return;
        }
        if(!this.validate()) return;
        if(this.currentEmployeeContractStep == 1){
          const naw = this.$parent.nawData;
          naw.first_name = this.contractData.employee.first_name;
          naw.prefix = this.contractData.employee.prefix;
          naw.last_name = this.contractData.employee.last_name;
          if(!this.$parent.contractData.company_id) this.$parent.contractData.company_id = store.getters.getCurrentCompanyId;
          if(!this.$parent.contractData.job_function.company_id) this.$parent.contractData.job_function.company_id = this.$parent.contractData.company_id;
          naw.email = this.contractData.employee.email;
          this.$parent.setArticles();
        }
        else if(this.currentEmployeeContractStep == 2){
          this.shouldGenerateContract = true;
          this.$parent.setTemplate();
        }
        if(this.currentEmployeeContractStep == 1 || this.currentEmployeeContractStep == 2){
          this.currentEmployeeContractStep++;
        }
        window.scrollTo(0, 0);
      },
      sendContractData(nextFormStep){
        if(this.contractData.files){
          this.contractData.files = this.setFiles(this.contractData.files);
        }
        this.isSaving = true;
        if(this.sendDirectlyToNMBRS){
          const data = {};
          data.job_function = {...this.contractData.job_function};
          for(let field in this.contractData){
            if(field == 'job_function') continue;
            data[field] = this.contractData[field];
          }
          data.employee = {};
          data.employee.address = {...this.$parent.nawData.address};
          for(let field in this.$parent.nawData){
            if(field == 'address' || field == 'contract') continue;
            data.employee[field] = this.$parent.nawData[field];
          }
          data.external_id = data.employee.external_id;
          contractService.updateNMBRS(store.getters.getCurrentCustomerId, data).then(() => {
            this.isSaving = false;
            this.$parent.formSection = nextFormStep;
          }).catch((e) => {
            this.toastError(e);
            this.isSaving = false;
          });
        }
        else if(this.contractData.id){
          this.contractData.no_sign_request = (nextFormStep == 'download' || !this.$parent.nawData.iban);
          contractService.update(this.contractData).then((response) => {
            this.isSaving = false;
            if(nextFormStep == 'download'){
              this.downloadPDF(this.contractData);
            }
            else if(nextFormStep == 'save' || !nextFormStep){
              window.location.href = window.location.origin + "/employees";
            }
            else{
              this.contractData.id = response.data.id;
              this.$parent.formSection = nextFormStep;
            }
          }).catch((e) => {
            this.toastError(e);
            this.isSaving = false;
          });
        }
        else{
          const naw = this.$parent.nawData;
          if(!naw.company_id){
            this.$toast.error("ID Bedrijf kan niet worden opgehaald");
            return;
          }
          contractService.store(this.contractData).then((response) => {
            if(nextFormStep){
              this.contractData.id = response.data.id;
              naw.id = response.data.employee_id;
              naw.employee_status_id = 3;
            }
            employeeService.update(naw).then(() => {
              this.isSaving = false;
              if(nextFormStep == 'download'){
                this.downloadPDF(this.contractData);
              }
              else if(nextFormStep){
                this.$parent.formSection = nextFormStep;
                naw.employee_status_id = 4;
              }
              else 
                window.location.href = window.location.origin + "/employees";
            }).catch((e) => {
              this.toastError(e);
              this.isSaving = false;
            });
          }).catch((e) => {
            this.toastError(e);
            this.isSaving = false;
          });
        }
      },
      downloadContractDirect(){
        contractService.downloadDirect(this.contractData.document, store.getters.getCurrentCompanyId).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const companyName = this.getCompany().replaceAll(' ', '-');
          const item = this.contractData;
          const generateDate = moment().format('YYYY-MM-DD');
          const pdfName = companyName + "_" + item.employee.first_name.replace(' ', '-') + '_' + (item.employee.prefix ? (item.employee.prefix + '_') : '') + item.employee.last_name.replace(' ', '-') + "_" + generateDate;
          link.setAttribute("download", pdfName + ".pdf");
          document.body.appendChild(link);
          link.click();
          this.isSaving = false;
        });
      },
      getInfoText(section, step){
        const identifier = 'employee_' + section + '_' + step;
        return this.infoTexts[identifier] ? this.infoTexts[identifier] : '';
      },
      getListClass(step){
        const currentStep = this.currentEmployeeStep;
        return step < currentStep ? "check" : (step == currentStep ? "active" : "inactive");
      },
      calculateTotalHours(e){
        let total = 0;
        this.schedule.forEach(hours => {
          const value = Number(hours);
          total += ((!hours || isNaN(value)) ? 0 : value);
        });
        total = total / 2;
        this.contractData.job_function.timetable = this.schedule.join(',');
        this.contractData.job_function.hours = total;
      },
      setMailInfo(e){
        this.currentEmployeeStep++;
        this.mail.subject = "Uitnodiging " + this.getCompany();
        this.mail.email_body = 
`Beste ${this.contractData.employee.first_name} ${this.contractData.employee.prefix ? (this.contractData.employee.prefix + ' ' + this.contractData.employee.last_name) : this.contractData.employee.last_name},

Voordat we jouw salaris kunnen betalen hebben wij jouw persoonlijke gegevens nodig.
Via onderstaande knop kun je deze snel en gemakkelijk aanleveren. 
Wij gaan vertrouwelijk met de gegevens om en slaan deze alleen op in jouw personeelsdossier.
Let op: zolang jij geen gegevens hebt aangeleverd, kunnen wij jou niet verlonen.

Bedankt!`
      },
      responsiveListControl(list, removeIndex = null){
        if(removeIndex == null)
          list.push('');
        else{
          list.splice(removeIndex, 1);
        }
      },
      saveSection(nextFormStep){
        if(!this.validate()) return;
        if(this.sendDirectlyToNMBRS && nextFormStep == 'download'){
          this.downloadContractDirect();
          return;
        }
        this.sendContractData(nextFormStep);
      },
      sendMailForNAW(){
        if(!this.validate()) return;
        this.sendingMail = true;
        contractService.mail(this.mail, this.contractData.id).then((response) => {
          this.$router.push({ name: "employees" });
        }).catch(this.toastError);
      },
      setProbationTime(){
        const startDate = this.contractData.job_function.start_date;
        if(!startDate) return;

        const months = this.contractData.job_function.probation_expiration_month;
        this.contractData.job_function.probation_expiration_date = moment(this.contractData.job_function.start_date).add(months, 'M').subtract(1, "days").format('YYYY-MM-DD');
      },
      setContractEndTime(){
        const startDate = this.contractData.job_function.start_date;
        if(!startDate) return;

        const months = this.contractData.job_function.fixed_term_expiration_month;
        this.contractData.job_function.fixed_term_expiration_date = moment(this.contractData.job_function.start_date).add(months, 'M').subtract(1, "days").format('YYYY-MM-DD');
      },
      calcTravelCompensation(){
        //const ratio = Number(this.contractData.job_function.travel_compensation_type);
        const multiplier = (((214 * 0.23) / 5) * 2) / 12;
        this.contractData.job_function.travel_compensation = Math.ceil(multiplier * Number(this.contractData.job_function.travel_compensation_distance) * 
            Number(this.contractData.job_function.travel_compensation_days) * 100) / 100;
      },
      calcWorkHomeCompensation(){
        this.contractData.job_function.work_days_at_home_compensation = (214 * 2.15 * Number(this.contractData.job_function.total_work_days_at_home)) / 12;
      },
      setWorkHomeCompensation(hasComp){
        this.contractData.job_function.has_wage_tax_credit = hasComp;
        this.contractData.job_function.total_work_days_at_home = null;
        this.contractData.job_function.work_days_at_home_compensation = null;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      setTextAreaRows(text){
        const rows = text.replace(/[^\n]/g, '').length + 1;
        return rows < 4 ? 4 : rows;
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },
      setArticleIndexes(e){
        this.refreshContract();
        this.$parent.setArticleIndexes(e);
      },
      getCompany(){
        const name = store.getters.getCurrentCompanyName;
        if(!name){
          const el = document.getElementById('company-select');
          if(!el || el.selectedIndex === -1) return '-';
          return el.options[el.selectedIndex].innerHTML;
        }
        return name;
      },
      isValidProbationOption(value){
        if(!this.contractData.job_function.start_date || (this.contractData.job_function.fixed_term && !this.contractData.job_function.fixed_term_expiration_date)){
          this.contractData.job_function.probation = false;
          return false;
        }
        if(this.contractData.job_function.fixed_term){
          let d1 = new Date(this.contractData.job_function.start_date);
          let d2 = new Date(this.contractData.job_function.fixed_term_expiration_date);
          d2.setDate(d2.getDate() + 1);
          let months;
          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth();
          const diff = months <= 0 ? 0 : months;
          if(value == 1 && diff < 6 && this.contractData.job_function.probation){
            this.contractData.job_function.probation = false;
          }
          return value == 1 ? diff >= 6 : diff >= 24;
        }
        return true;
      },
      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
      downloadPDF(item) {
        this.item = {...item};
        this.isSaving = true;
        contractService.download(this.item.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const companyName = this.getCompany().replaceAll(' ', '-');
            const pdfName = companyName + "_" + item.employee.first_name.replace(' ', '-') + '_' + (item.employee.prefix ? (item.employee.prefix + '_') : '') + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
            link.setAttribute("download", pdfName + ".pdf");
            document.body.appendChild(link);
            link.click();
            this.isSaving = false;
        }).catch((e) => {
          this.toastError(e);
          this.isSaving = false;
        });
      },
      addContractFile(file, index){
        if(!this.contractData.files) this.contractData.files = [null];
        fileService.store(file).then(response => {
          this.contractData.files[index] = response.data;
        });
      },
      removeContractFile(index){
        this.contractData.files[index] = null;
      },
      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      },

      setNextFormStep(){
        this.$parent.formSection = 2;
      },
    },

  }
</script>